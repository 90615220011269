// Variable overrides
//Responsive Mixin
@mixin bp($class) {
    @if $class == xs {
        @media (max-width: 400px){
            @content;
        }
    }
    @else if $class == sm {
        @media (max-width: 575px){
            @content;
        }
    }
    @else if $class == md {
        @media (max-width: 768px){
            @content;
        }
    }
    @else if $class == lg {
        @media (max-width: 992px){
            @content;
        }
    }
    @else if $class == xl {
        @media (max-width: 1400px){
            @content;
        }
    }
    @else if $class == xxl {
        @media (min-width: 1400px){
            @content;
        }
    }
    @else if $class == xs {
        @media (max-width: 400px){
            @content;
        }
    }
    @else {
        @warn "Breakpoint mixin supports only xs, sm, md, lg, xl & xxl.";
    }
}