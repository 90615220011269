// Here you can add other styles

  //forgotpassword
  /* Forgot Password */
  .forgotPwdlabl {
    //font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.05em;
    padding: 0px 20px 20px 194px;
    color: #264653;
  }
  
  .loginBox{
    padding:30px;
    border-radius: 25px;
    background: #FFFFFF;
    width: 450px;
    margin: 0 auto;
    height: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    animation: nav-load 300ms ease-in;
  }
  @keyframes nav-load {
    0%{
      transform: translateX(100%);
    }
    100%{
      transform: translateX((0));
    }
    
  }
@media screen and (min-width: 768px) {
  .LoginRow{
    position: absolute;
    right: 130px;
    top: 50%;
    transform: translateY(-50%);
  }
}

